@import "theme";

html {
	height: 100%;
}

body {
	margin: 0;
	height: 100%;
}

// ルートコンテナ
.application-container {
	height: 100%;
	display: flex;
	flex-direction: column;
}

// コンテンツコンテナー
.application-contents-container {
	height: 100%;
}

.content-container {
	background-color: white;
	padding: 20px;
}

.content-border-container {
	border: 1px solid #383333;
	border-radius: 6px;
	margin: 30px;
	padding: 15px 10px;
}

.search-input-container {
	display: flex;
	flex-direction: row;
	padding: 3px 15px;
	flex-wrap: wrap;

	.search-container {
		display: flex;
		flex-direction: column;
		min-width: 150px;
	}

	.search-container:nth-child(n) {
		margin: 0 10px;
	}
}


/////////////////////////////////
// パーツ用スタイル
input {
	font-size: 1.071428571rem;
	border-radius: 8px;
	border: 1px solid #999999;
	padding-inline-start: 1px;
	padding: 5px;
	height: 30px;
}

.border-img {
	width: 100%;
	height: 3px;
}

button {
	border-radius: 20px !important;
	width: 200px;
	margin: 5px;
}


.table-container {
	overflow: auto;
	width: 100%;
}

mat-table {
	width: 100%;
}

// テーブルセル
td,th {
	//min-width: 65px !important;
	white-space: nowrap !important;
}