/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzFhMTIzOCIsIj9lcjwjYmFiOGMzIiwiO2VyPCMwZjBhMjR$LCIlPmBePCM5NDA5MDkiLCI~ZXI8I2RmYjViNSIsIjtlcjwjNzcwNTA1fiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMxYTEyMzgiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMxYTEyMzh$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/

@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

$my-primary-palette: (
		50 : #e4e3e7,
		100 : #bab8c3,
		200 : #8d899c,
		300 : #5f5974,
		400 : #3c3656,
		500 : #1a1238,
		600 : #171032,
		700 : #130d2b,
		800 : #0f0a24,
		900 : #080517,
		A100 : #6758ff,
		A200 : #3825ff,
		A400 : #1600f1,
		A700 : #1300d7,
		contrast: (
				50 : #000000,
				100 : #000000,
				200 : #000000,
				300 : #ffffff,
				400 : #ffffff,
				500 : #ffffff,
				600 : #ffffff,
				700 : #ffffff,
				800 : #ffffff,
				900 : #ffffff,
				A100 : #ffffff,
				A200 : #ffffff,
				A400 : #ffffff,
				A700 : #ffffff,
		)
);

$my-accent-palette: (
		50 : #f2e1e1,
		100 : #dfb5b5,
		200 : #ca8484,
		300 : #b45353,
		400 : #a42e2e,
		500 : #940909,
		600 : #8c0808,
		700 : #810606,
		800 : #770505,
		900 : #650202,
		A100 : #ff9595,
		A200 : #ff6262,
		A400 : #ff2f2f,
		A700 : #ff1515,
		contrast: (
				50 : #000000,
				100 : #000000,
				200 : #000000,
				300 : #ffffff,
				400 : #ffffff,
				500 : #ffffff,
				600 : #ffffff,
				700 : #ffffff,
				800 : #ffffff,
				900 : #ffffff,
				A100 : #000000,
				A200 : #000000,
				A400 : #ffffff,
				A700 : #ffffff,
		)
);




$fontConfig: (
		display-4: mat.m2-define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
		display-3: mat.m2-define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
		display-2: mat.m2-define-typography-level(45px, 48px, 400, 'Roboto', 0.0000em),
		display-1: mat.m2-define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
		headline: mat.m2-define-typography-level(24px, 32px, 400, 'Roboto', 0.0000em),
		title: mat.m2-define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
		subheading-2: mat.m2-define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
		subheading-1: mat.m2-define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
		body-2: mat.m2-define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
		body-1: mat.m2-define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
		button: mat.m2-define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
		caption: mat.m2-define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
		input: mat.m2-define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px)
);

// Foreground Elements

// Light Theme Text
$dark-text: #1a1238;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
		base:              black,
		divider:           $dark-dividers,
		dividers:          $dark-dividers,
		disabled:          $dark-disabled-text,
		disabled-button:   rgba($dark-text, 0.26),
		disabled-text:     $dark-disabled-text,
		elevation:         black,
		secondary-text:    $dark-accent-text,
		hint-text:         $dark-disabled-text,
		accent-text:       $dark-accent-text,
		icon:              $dark-accent-text,
		icons:             $dark-accent-text,
		text:              $dark-primary-text,
		slider-min:        $dark-primary-text,
		slider-off:        rgba($dark-text, 0.26),
		slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
		base:              $light-text,
		divider:           $light-dividers,
		dividers:          $light-dividers,
		disabled:          $light-disabled-text,
		disabled-button:   rgba($light-text, 0.3),
		disabled-text:     $light-disabled-text,
		elevation:         black,
		hint-text:         $light-disabled-text,
		secondary-text:    $light-accent-text,
		accent-text:       $light-accent-text,
		icon:              $light-text,
		icons:             $light-text,
		text:              $light-text,
		slider-min:        $light-text,
		slider-off:        rgba($light-text, 0.3),
		slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#1a1238, 20%);
$dark-bg-alpha-4:     rgba(#1a1238, 0.04);
$dark-bg-alpha-12:    rgba(#1a1238, 0.12);

$mat-light-theme-background: (
		background:               $light-background,
		status-bar:               $light-bg-darker-20,
		app-bar:                  $light-bg-darker-5,
		hover:                    $dark-bg-alpha-4,
		card:                     $light-bg-lighter-5,
		dialog:                   $light-bg-lighter-5,
		tooltip:                  $dark-bg-tooltip,
		disabled-button:          $dark-bg-alpha-12,
		raised-button:            $light-bg-lighter-5,
		focused-button:           $dark-focused,
		selected-button:          $light-bg-darker-20,
		selected-disabled-button: $light-bg-darker-30,
		disabled-button-toggle:   $light-bg-darker-10,
		unselected-chip:          $light-bg-darker-10,
		disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #1a1238;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#fafafa, 0.04);
$light-bg-alpha-12:   rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
		background:               $dark-background,
		status-bar:               $dark-bg-lighter-20,
		app-bar:                  $dark-bg-lighter-5,
		hover:                    $light-bg-alpha-4,
		card:                     $dark-bg-lighter-5,
		dialog:                   $dark-bg-lighter-5,
		tooltip:                  $dark-bg-lighter-20,
		disabled-button:          $light-bg-alpha-12,
		raised-button:            $dark-bg-lighter-5,
		focused-button:           $light-focused,
		selected-button:          $dark-bg-lighter-20,
		selected-disabled-button: $dark-bg-lighter-30,
		disabled-button-toggle:   $dark-bg-lighter-10,
		unselected-chip:          $dark-bg-lighter-20,
		disabled-list-option:     $dark-bg-lighter-10,
);

// Compute font config
//@include mat.core($fontConfig);
$my-primary: mat.m2-define-palette($my-primary-palette, 500);
$my-accent: mat.m2-define-palette($my-accent-palette, 500);
$my-warn: mat.m2-define-palette(mat.$m2-red-palette, 500);

$my-theme: mat.m2-define-light-theme((
		color: (
				primary: $my-primary,
				accent: $my-accent,
				warn: $my-warn,
		),
		typography: $fontConfig,
		density: 0,
));

@include mat.all-component-themes($my-theme);


// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
	padding: 0 1.15em;
	margin: 0 .65em;
	min-width: 3em;
	line-height: 36.4px
}

.mat-standard-chip {
	padding: .5em .85em;
	min-height: 2.5em;
}

.material-icons {
	font-size: 24px;
	font-family: 'Material Icons', 'Material Icons';
	.mat-badge-content {
		font-family: 'Roboto';
	}
}
